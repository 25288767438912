@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Slackey&display=swap);
body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-size: 2.5vh;
  color: #d0d0d0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(circle at center, gray 0, black 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 250px) {

  .Minter {
    padding: 0 0;
    margin: 0 auto;
    text-align: left;
  }

}

@media (min-width: 1200px) {

  .Minter {
    padding: 7.5vh 7.5vh;
    margin: 0 auto;
    text-align: left;
  }

}

@media (min-width: 250px) {

#sauce-logo {
  height: 300px;
  max-width: 100%;
}
  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  * {

  }

  .nft-single {
    position: relative;
    margin: 15px auto;
    padding: 10px;
    border: 5px solid white;
    width: 75vw;
    background-color: black;
    border-radius: 8px;
    white-space: nowrap;
    text-align: center;
  }

  .prize-text-container {

  }

  .prize-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    word-wrap: break-word;
    position: relative;
  }

  .carousel-nav {
    border: 2px solid gray;
    margin: 0 1vw;
    background: black;
    color: white;
    cursor: pointer;
  }

  .carousel-nav:hover {
    background: black;
    opacity: 0.7;
  }

  .nav-left {

  }

  .nav-right {

  }

  #carousel-container {
    width:90vw;
  }

  #nft-container {

    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12vw 0px;
    width: 300px;
  }

  #main-prize-container {
    margin-top: 5vh;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2vw 0vw 0px 0vw;
    width: 90vw;
  }

  #secondary-prize-container {

  }

  .nft {
    margin: 5px;
    max-width: 25vw;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid black;
    padding: 3px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background-clip: content-box, padding-box;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
  }

  .main-prize {
    max-width: 50vw;
    box-shadow: 0 0 100px red;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid black;
    padding: 4px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background-clip: content-box, padding-box;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }

  a {
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  .social-icon {
    font-size: 5vh;
    display: flex;
    justify-content: center;
  }

  .os-icon {
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
    object-fit: cover;

  }

  .icon-container {

    margin: 7px 5px;
    width: 5vh;
    height: 5vh;
    overflow: hidden;
    display: inline-flex;
  }

  .os-text-container {

    margin: 0px 0px;
    width: 2.3vh;
    height: 2.3vh;
    overflow: hidden;
    display: inline-flex;
  }

  .break {
  flex-basis: 100%;
  height: 0;
}



  button {
    padding: 9px 16px;
    max-height: 40px;
    border-color: #f8e897;
    color: #c5fbc3;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
  }

  #amount {
    font-size: 24px;
    padding: 9px 16px;
  }

  #price {
    font-size: 24px;
    padding: 10px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  #title {
    padding-top: 32px;
  }

  #status {
    color:#dd0008;
  }

  #text-content {
    max-width: 80vw;
  }

  .spacer {

  }

  .big-spacer {
    padding: 10vh;
  }

  .qty {
    width: 65px;
    background-color: black;
    color: white;
    border: 2px solid gray;
    cursor: pointer;
  }

  .qty:hover {
    color: #ffffff;
    background: rgba(3,78,136,0.7);
    cursor: pointer;
  }

  #selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-width: 30vw;
  }

  #logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ctr {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }

  #logo {
    max-width: 100%;
    max-height: 33vh;
  }

  #text-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #prizes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #drop-title {
    font-size: 5vh;
    height: 7.5vh;
    background-color: black;
    width: 50vw;
    border-radius: 16px;
    border: 1px solid black;
    padding: 4px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background-clip: content-box, padding-box;
  }

  #walletButton {
    position: fixed;
    z-index: 5;
    margin-top: 25px;
    margin-left: 25px;
    border-radius: 16px;
    text-align: right;
    margin-bottom: 30px;
    line-height: 16px;
    background-color: black;
    color: white;
    border: 2px solid gray;
    box-shadow: 5px 5px 10px rgba(215,0,8,1);
  }

  #walletButtonConnected {
    position: fixed;
    z-index: 5;
    margin-top: 25px;
    margin-left: 25px;
    border-radius: 16px;
    text-align: right;
    margin-bottom: 30px;
    line-height: 16px;
    background-color: black;
    color: white;
    border: 2px solid gray;
    box-shadow: 5px 5px 10px rgba(0,215,8,1);
  }

  #walletButton:hover {
    color: #ffffff;
    background: #20557d;
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(215,215,8,1);
  }

  #walletButtonConnected:hover {
    color: #ffffff;

    cursor: pointer;

  }

  .price {

  }

  #mint-counter {
    font-weight: 600;
    color: black;
  }

  .social-link {
    text-decoration: none;
    font-weight: 600;
    color: white;
  }

  #mintButton2 {
    position: relative;
    background: #000;
    padding: 10px 42px;
    margin-bottom: 0vh;
    border-radius: 8px;
    border: 1px solid gray;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    letter-spacing: 0.2em;
    box-shadow: 0 8px 32px rgba(0,0,0,0.2);
    transition: all 0.2s ease;
  }
  #mintButton2:after {
    content: '';
    position: absolute;
    top: 0;
    left: -200%;
    width: 200%;
    height: 100%;
    -webkit-transform: skewX(-20deg);
            transform: skewX(-20deg);
    background-image: linear-gradient(to right, transparent, rgba(255,255,255,0.3), transparent);
  }
  #mintButton2:hover:after {
    -webkit-animation: shine 1.6s ease;
            animation: shine 1.6s ease;
  }
  #mintButton2:active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    box-shadow: 0 4px 16px rgba(0,0,0,0.2);
    background-color: red;
  }
  .cntr {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  @-webkit-keyframes shine {
    100% {
      left: 200%;
    }
  }
  @keyframes shine {
    100% {
      left: 200%;
    }
  }

  .shape{
    text-align:center;
    background-color:rgba(3,78,136,0.7);
    width:200px;
    height:60px;
    line-height:60px;
    color:white;
    margin:20px auto;
    position:relative;
  }
  .shape:before{
    content:"";
    width:0px;
    height:0px;
    border-top:60px solid rgba(3,78,136,0.7);
    border-left:60px solid transparent;
    position:absolute;
    right:100%;
    top:0px;
  }

  #priceTag {
    font-size: 5vh;
  }

  #countButton {

    background-color: #ffffff;
    color:#000000;

  }

  #testnet {
    color: red;
    text-align: right;
    margin-right: 5vh;
  }

  /* Style The Dropdown Button */
  .dropbtn {
    width: 65px;
    background-color: black;
    color: white;
    border: 2px solid gray;
    cursor: pointer;
  }

  .dropbtn:hover {
    background-color: rgba(3,78,136,0.7);
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent;
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content span {
    width: 51px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding: 5px 5px;
    text-decoration: none;
    border: 2px solid gray;
    display: block;
    max-height: 40px;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content span:hover {background-color: rgba(3,78,136,0.7)}

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover  {
    background-color: gray;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
  }

  #mint-now {
    display: inline-block;
    background-color: black;
    color: white;
    border-color: white;
    cursor: pointer;
    margin: 35px 0 50px 0;
  }

}

@media (min-width: 600px) {

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  * {
  }

  .prize-text-container {
    width:100%;
    height:58px;
    max-width: 600px;

    display:block;
    max-width: 600px;
    font-family:Arial, Helvetica, sans-serif;
    font-size:19px;

    text-align:center; padding-top:12px;
  }

  .carousel-container {
    width:90vw;
  }

  #carousel-container {
    width:90vw;
    height: 45vh;
    margin-bottom: 25px;
  }

  #nft-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90vw;
    height: auto;
    padding: 0 0;
  }

  .nft-single {
    margin: 1vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30vw;
    margin: 1vw;
    overflow: inherit;
    top: inherit;
    -webkit-transform: inherit;
            transform: inherit;
    padding: 40px 0;
  }

  .nft {
    margin: 10px 5px;
    max-width: 10vw;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid black;
    padding: 4px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background-clip: content-box, padding-box;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
  }

  .main-prize {
    max-width: 14.7vw;
    box-shadow: 0 0 100px red;
  }

  a {
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  .social-icon {
    font-size: 5vh;
    display: flex;
    justify-content: center;
  }

  .os-icon {
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
    object-fit: cover;

  }

  .icon-container {

    margin: 7px 5px;
    width: 5vh;
    height: 5vh;
    overflow: hidden;
    display: inline-flex;
  }


  button {
    padding: 9px 16px;
    max-height: 40px;
    border-color: #f8e897;
    color: #c5fbc3;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
  }

  #amount {
    font-size: 24px;
    padding: 9px 16px;
  }

  #price {
    font-size: 24px;
    padding: 10px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  #title {
    padding-top: 32px;
  }

  #status {
    color:#dd0008;
  }

  #text-content {
    max-width: 80vw;
  }

  .spacer {
    padding: 1vh;
  }

  .big-spacer {
    padding: 10vh;
  }

  .qty {
    width: 65px;
    background-color: black;
    color: white;
    border: 2px solid gray;
    cursor: pointer;
  }

  .qty:hover {
    color: #ffffff;
    background: rgba(3,78,136,0.7);
    cursor: pointer;
  }

  #selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-width: 15vw;
    padding: 0vw 35vw;
  }

  #logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #logo {
    max-width: 100%;
    max-height: 33vh;
  }

  #drop-title {
    font-size: 10vh;
    height: 15vh;
    background-color: black;
    width: 25vw;
    border-radius: 16px;
    border: 1px solid black;
    padding: 4px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background-clip: content-box, padding-box;
  }



  .price {

  }

  #mint-counter {
    font-weight: 600;
    color: black;
  }

  .social-link {
    text-decoration: none;
    font-weight: 600;
    color: white;
  }

  #mintButton2 {
    position: relative;
    background: #000;
    padding: 10px 42px;
    margin-bottom: 0vh;
    border-radius: 8px;
    border: 1px solid gray;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    letter-spacing: 0.2em;
    box-shadow: 0 8px 32px rgba(0,0,0,0.2);
    transition: all 0.2s ease;
  }
  #mintButton2:after {
    content: '';
    position: absolute;
    top: 0;
    left: -200%;
    width: 200%;
    height: 100%;
    -webkit-transform: skewX(-20deg);
            transform: skewX(-20deg);
    background-image: linear-gradient(to right, transparent, rgba(255,255,255,0.3), transparent);
  }
  #mintButton2:hover:after {
    -webkit-animation: shine 1.6s ease;
            animation: shine 1.6s ease;
  }
  #mintButton2:active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    box-shadow: 0 4px 16px rgba(0,0,0,0.2);
    background-color: red;
  }
  .cntr {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  @-webkit-keyframes shine {
    100% {
      left: 200%;
    }
  }
  @keyframes shine {
    100% {
      left: 200%;
    }
  }

  .shape{
    text-align:center;
    background-color:rgba(3,78,136,0.7);
    width:200px;
    height:60px;
    line-height:60px;
    color:white;
    margin:20px auto;
    position:relative;
  }
  .shape:before{
    content:"";
    width:0px;
    height:0px;
    border-top:60px solid rgba(3,78,136,0.7);
    border-left:60px solid transparent;
    position:absolute;
    right:100%;
    top:0px;
  }

  #priceTag {
    font-size: 5vh;
  }

  #countButton {

    background-color: #ffffff;
    color:#000000;

  }

  #testnet {
    color: red;
    text-align: right;
    margin-right: 5vh;
  }

  /* Style The Dropdown Button */
  .dropbtn {
    width: 65px;
    background-color: black;
    color: white;
    border: 2px solid gray;
    cursor: pointer;
  }

  .dropbtn:hover {
    background-color: rgba(3,78,136,0.7);
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent;
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    width: 51px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding: 5px 5px;
    text-decoration: none;
    border: 2px solid gray;
    display: block;
    max-height: 40px;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: rgba(3,78,136,0.7)}

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover  {
    background-color: gray;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
  }

}

@media (min-width: 900px) {

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  * {
  }

  .prize-text-container {
    width:100%;
    height:58px;
    max-width: 600px;

    display:block;
    max-width: 600px;
    font-family:Arial, Helvetica, sans-serif;
    font-size:19px;

    text-align:center; padding-top:12px;
  }

  .carousel-container {
    width:90vw;
  }

  #carousel-container {
    width:90vw;
    height: 45vh;
    margin-bottom: 25px;
  }

  #nft-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90vw;
    height: auto;
    padding: 0 0;
  }

  .nft-single {
    margin: 1vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 20vw;
    overflow: inherit;
    top: inherit;
    -webkit-transform: inherit;
            transform: inherit;
    padding: 40px 0;
  }

  .nft {
    margin: 10px 5px;
    max-width: 10vw;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid black;
    padding: 4px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background-clip: content-box, padding-box;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
  }

  .main-prize {
    max-width: 14.7vw;
    box-shadow: 0 0 100px red;
  }

  a {
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  .social-icon {
    font-size: 5vh;
    display: flex;
    justify-content: center;
  }

  .os-icon {
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
    object-fit: cover;

  }

  .icon-container {

    margin: 7px 5px;
    width: 5vh;
    height: 5vh;
    overflow: hidden;
    display: inline-flex;
  }


  button {
    padding: 9px 16px;
    max-height: 40px;
    border-color: #f8e897;
    color: #c5fbc3;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
  }

  #amount {
    font-size: 24px;
    padding: 9px 16px;
  }

  #price {
    font-size: 24px;
    padding: 10px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  #title {
    padding-top: 32px;
  }

  #status {
    color:#dd0008;
  }

  #text-content {
    max-width: 80vw;
  }

  .spacer {
    padding: 1vh;
  }

  .big-spacer {
    padding: 10vh;
  }

  .qty {
    width: 65px;
    background-color: black;
    color: white;
    border: 2px solid gray;
    cursor: pointer;
  }

  .qty:hover {
    color: #ffffff;
    background: rgba(3,78,136,0.7);
    cursor: pointer;
  }

  #selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-width: 15vw;
    padding: 0vw 35vw;
  }

  #logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #logo {
    max-width: 100%;
    max-height: 33vh;
  }

  #drop-title {
    font-size: 10vh;
    height: 15vh;
    background-color: black;
    width: 25vw;
    border-radius: 16px;
    border: 1px solid black;
    padding: 4px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%), linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background-clip: content-box, padding-box;
  }



  .price {

  }

  #mint-counter {
    font-weight: 600;
    color: black;
  }

  .social-link {
    text-decoration: none;
    font-weight: 600;
    color: white;
  }

  #mintButton2 {
    position: relative;
    background: #000;
    padding: 10px 42px;
    margin-bottom: 0vh;
    border-radius: 8px;
    border: 1px solid gray;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    letter-spacing: 0.2em;
    box-shadow: 0 8px 32px rgba(0,0,0,0.2);
    transition: all 0.2s ease;
  }
  #mintButton2:after {
    content: '';
    position: absolute;
    top: 0;
    left: -200%;
    width: 200%;
    height: 100%;
    -webkit-transform: skewX(-20deg);
            transform: skewX(-20deg);
    background-image: linear-gradient(to right, transparent, rgba(255,255,255,0.3), transparent);
  }
  #mintButton2:hover:after {
    -webkit-animation: shine 1.6s ease;
            animation: shine 1.6s ease;
  }
  #mintButton2:active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    box-shadow: 0 4px 16px rgba(0,0,0,0.2);
    background-color: red;
  }
  .cntr {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  @-webkit-keyframes shine {
    100% {
      left: 200%;
    }
  }
  @keyframes shine {
    100% {
      left: 200%;
    }
  }

  .shape{
    text-align:center;
    background-color:rgba(3,78,136,0.7);
    width:200px;
    height:60px;
    line-height:60px;
    color:white;
    margin:20px auto;
    position:relative;
  }
  .shape:before{
    content:"";
    width:0px;
    height:0px;
    border-top:60px solid rgba(3,78,136,0.7);
    border-left:60px solid transparent;
    position:absolute;
    right:100%;
    top:0px;
  }

  #priceTag {
    font-size: 5vh;
  }

  #countButton {

    background-color: #ffffff;
    color:#000000;

  }

  #testnet {
    color: red;
    text-align: right;
    margin-right: 5vh;
  }

  /* Style The Dropdown Button */
  .dropbtn {
    width: 65px;
    background-color: black;
    color: white;
    border: 2px solid gray;
    cursor: pointer;
  }

  .dropbtn:hover {
    background-color: rgba(3,78,136,0.7);
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent;
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    width: 51px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding: 5px 5px;
    text-decoration: none;
    border: 2px solid gray;
    display: block;
    max-height: 40px;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: rgba(3,78,136,0.7)}

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover  {
    background-color: gray;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
  }

}

