@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-size: 2.5vh;
  color: #d0d0d0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(circle at center, gray 0, black 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 250px) {

  .Minter {
    padding: 0 0;
    margin: 0 auto;
    text-align: left;
  }

}

@media (min-width: 1200px) {

  .Minter {
    padding: 7.5vh 7.5vh;
    margin: 0 auto;
    text-align: left;
  }

}
